<template>
	<div>
		<h1 class="mb-4">{{ title }}</h1>
		<v-filters
			class="mb-4"
			v-bind="{
				filters: urlFilters,
				actionTypes: ACTIONS_TYPES,
				loading
			}"
			@update-filters="updateUrl"
		/>
		<v-tabs
			:value="urlFilters.source"
			class="mb-1"
			grow
		>
			<v-tab
				v-for="item of OPTIONS_SOURCE"
				:key="item.id"
				:tab-value="item.id"
				@change="updateUrl({source: item.id})"
			>
				<span>{{ item.text }}</span>
			</v-tab>
		</v-tabs>
		<v-data-table
			class="sessions-table"
			:headers="headers"
			:items="frequencyUpdatesSavedAndTotal"
			:loading="loading.table"
			:server-items-length="frequencyUpdatesSaved.length"
			disable-pagination
			hide-default-footer
			@update:sort-by="updateSortBy"
			@update:sort-desc="updateSortDesc"
		>
			<template v-slot:item.number="{ item, index }">
				<span v-if="!item.total">{{ index }}</span>
			</template>
			<template v-slot:item.client_id="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}"><sup>{{ item.client_id }}</sup> {{item.total ? 'Total' : findClientName(item.client_id) }}</span>
			</template>
			<template v-slot:item.project_id="{ item,index }">
				<div :class="{'d-flex justify-space-between': index !== 0}">
					<span :class="{'font-weight-bold': index === 0}"><sup v-if="!item.total">{{ item.project_id }}</sup> {{item.total ? item.total_rows_cnt  : item.project_name}}</span>
					<a
						v-if="!item.total"
						:href="generateLink(item)"
						target="_blank"
					>
					<v-icon small color="gray">mdi-link</v-icon>
					</a>
				</div>
			</template>
			<template v-slot:item.host_name="{ item,index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.host_name }}</span>
			</template>
			<template v-slot:item.date="{ item }">
				<span class="text-no-wrap">{{ item.date }}</span>
			</template>
			<template v-slot:item.ws.expected.ws1_queries_cnt="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_expected_ws1_queries_cnt.toLocaleString() : item.ws.expected.ws1_queries_cnt.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.expected.ws2_queries_cnt="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_expected_ws2_queries_cnt.toLocaleString() : item.ws.expected.ws2_queries_cnt.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.expected.total_queries_cnt="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_expected_frequency_queries_cnt.toLocaleString() : item.ws.expected.total_queries_cnt.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.expected.error_queries_cnt="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_expected_error_queries_cnt.toLocaleString() : item.ws.expected.error_queries_cnt.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.finished.ws1_queries_cnt="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_finished_ws1_queries_cnt.toLocaleString() : item.ws.finished.ws1_queries_cnt.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.finished.ws2_queries_cnt="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_finished_ws2_queries_cnt.toLocaleString() : item.ws.finished.ws2_queries_cnt.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.finished.total_queries_cnt="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_finished_frequency_queries_cnt.toLocaleString() : item.ws.finished.total_queries_cnt.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.cnt.ws1="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_frequency_ws1_volume_cnt.toLocaleString() : item.ws.cnt.ws1.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.cnt.ws2="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_frequency_ws2_volume_cnt.toLocaleString() : item.ws.cnt.ws2.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.cnt.total="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_frequency_volume_cnt.toLocaleString() : item.ws.cnt.total.toLocaleString() }}</span>
			</template>
			<template v-slot:item.is_failed="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_failed_cnt.toLocaleString() : item.is_failed.toLocaleString() }}</span>
			</template>
			<template v-slot:item.is_ws1_failed="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_ws1_failed.toLocaleString() : item.is_ws1_failed.toLocaleString() }}</span>
			</template>
			<template v-slot:item.is_ws2_failed="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_ws2_failed.toLocaleString() : item.is_ws2_failed.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.prc.ws1_queries_prc="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_frequency_ws1_queries_prc.toLocaleString() : item.ws.prc.ws1_queries_prc.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.prc.ws2_queries_prc="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_frequency_ws2_queries_prc.toLocaleString() : item.ws.prc.ws2_queries_prc.toLocaleString() }}</span>
			</template>
			<template v-slot:item.ws.prc.total_queries_prc="{ item, index }">
				<span :class="{'font-weight-bold': index === 0}">{{ item.total ?  item.total_frequency_queries_prc.toLocaleString() : item.ws.prc.total_queries_prc.toLocaleString() }}</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { validateFilterFromOptions } from '@/function/validateFilter'
import { OPTIONS_SOURCE, NOTIFY_STATUS, OPTIONS_DEVICE } from '@/const'
import { mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'
import updateUrlMixin from '@/mixins/updateUrlMixin'
import errors from '@/store/errors'
import filters from './filters.vue'

const SORTING_AVAILABLE = [
	'date',
	'client_id',
	'project_id',
	'shard_name',
	'host_name',
	'region',
	'expected_ws1_queries_cnt',
	'expected_ws2_queries_cnt',
	'total_expected_frequency_queries_cnt',
	'total_expected_error_queries_cnt',
	'finished_ws1_queries_cnt',
	'finished_ws2_queries_cnt',
	'total_finished_frequency_queries_cnt',
	'frequency_ws1_queries_prc',
	'frequency_ws2_queries_prc',
	'total_frequency_queries_prc',
	'frequency_ws1_volume_cnt',
	'frequency_ws2_volume_cnt',
	'total_frequency_volume_cnt'
]

const ACTIONS_TYPES = ['auto', 'manual', 'new_semantics']

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	mixins: [updateUrlMixin],

	components: {
		'v-filters': filters
	},

	data () {
		return {
			title: 'Объёмы сборов частотности по проектам ch-proxy',
			loading: {
				clients: true,
				shards: true,
				table: true
			},
			OPTIONS_SOURCE,
			ACTIONS_TYPES,
			headers: [
				{
					text: '№',
					align: 'center',
					divider: true,
					value: 'number'
				},
				{
					text: 'Клиент',
					value: 'client_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Проект',
					value: 'project_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Дата',
					value: 'date',
					align: 'center',
					divider: true
				},
				{
					text: 'Шард',
					value: 'shard_name',
					align: 'center',
					divider: true
				},
				{
					text: 'Хост CH',
					value: 'host_name',
					divider: true
				},
				{
					text: 'Тип сбора',
					value: 'action_type',
					divider: true,
					sortable: false
				},
				{
					text: 'Устройство',
					value: 'device',
					divider: true,
					sortable: false
				},
				{
					text: 'Регион',
					value: 'region',
					divider: true
				},
				{
					text: 'vendor',
					value: 'vendor',
					divider: true,
					sortable: false
				},
				{
					text: 'ws1',
					value: 'ws.ws_types.ws1',
					divider: true,
					sortable: false
				},
				{
					text: 'ws2',
					value: 'ws.ws_types.ws2',
					divider: true,
					sortable: false
				},
				{
					text: 'expected ws1',
					value: 'ws.expected.ws1_queries_cnt',
					divider: true
				},
				{
					text: 'expected ws2',
					value: 'ws.expected.ws2_queries_cnt',
					divider: true
				},
				{
					text: 'expected total',
					value: 'ws.expected.total_queries_cnt',
					divider: true
				},
				{
					text: 'expected error',
					value: 'ws.expected.error_queries_cnt',
					divider: true
				},
				{
					text: 'finished ws1',
					value: 'ws.finished.ws1_queries_cnt',
					divider: true
				},
				{
					text: 'finished ws2',
					value: 'ws.finished.ws2_queries_cnt',
					divider: true
				},
				{
					text: 'finished total',
					value: 'ws.finished.total_queries_cnt',
					divider: true
				},
				{
					text: 'cnt ws1',
					value: 'ws.cnt.ws1',
					divider: true
				},
				{
					text: 'cnt ws2',
					value: 'ws.cnt.ws2',
					divider: true
				},
				{
					text: 'cnt total',
					value: 'ws.cnt.total',
					divider: true
				},
				{
					text: 'failed',
					value: 'is_failed',
					divider: true,
					sortable: false
				},
				{
					text: 'failed ws1',
					value: 'is_ws1_failed',
					divider: true,
					sortable: false
				},
				{
					text: 'failed ws2',
					value: 'is_ws2_failed',
					divider: true,
					sortable: false
				},
				{
					text: 'prc ws1',
					value: 'ws.prc.ws1_queries_prc',
					divider: true
				},
				{
					text: 'prc ws2',
					value: 'ws.prc.ws2_queries_prc',
					divider: true
				},
				{
					text: 'prc total',
					value: 'ws.prc.total_queries_prc',
					divider: true
				}
			]
		}
	},

	computed: {
		...mapState({
			shards: state => state.updates.shards,
			hosts: state => state.updates.hosts,
			clients: state => state.clients,
			projects: state => state.options.projects,
			frequencyUpdatesSaved: state => state.frequency.frequencyUpdatesSaved,
			frequencyUpdatesSavedTotals: state => state.frequency.frequencyUpdatesSavedTotals
		}),

		urlFilters () {
			const {
				date_from,
				date_to,
				client_id,
				project_id,
				source,
				shard_name,
				host_name,
				only_failed,
				device,
				action_type,
				region,
				hide_import,
				sort
			} = this.$route.query

			const today = new Date().toISOString().split('T')[0]
			const sortName = sort ? String(sort).replace('-', '') : 'total_frequency_queries_prc'
			const sortNameValidated = validateFilterFromOptions(sortName, SORTING_AVAILABLE, 'total_frequency_queries_prc')
			const sortWithDirection = String(sort).startsWith('-') ? `-${sortNameValidated}` : sortNameValidated
			const projectId = Array.isArray(project_id) ? project_id : [project_id]

			return {
				dateFrom: date_to && date_from ? date_from : today,
				dateTo: date_to && date_from ? date_to : today,
				clientId: validateFilterFromOptions(Array.isArray(client_id) ? client_id : [client_id], this.clients, [], true, true),
				projectId: projectId
					.map(item => +item)
					.filter(item => !isNaN(item)),
				source: validateFilterFromOptions(source, OPTIONS_SOURCE, 'yandex'),
				shardName: validateFilterFromOptions(shard_name, this.shards, [], true),
				hostName: validateFilterFromOptions(host_name, this.hosts, [], true),
				onlyFailed: String(only_failed) === 'true' ? 1 : 0,
				device: validateFilterFromOptions(device, OPTIONS_DEVICE, undefined),
				actionType: validateFilterFromOptions(action_type, ACTIONS_TYPES, undefined),
				region: region || undefined,
				hideImport: String(hide_import) === 'true' ? 1 : 0,
				sort: [sortWithDirection]
			}
		},

		frequencyUpdatesSavedAndTotal () {
			const firstRow = {
				total_expected_error_queries_cnt: this.frequencyUpdatesSavedTotals.total_expected_error_queries_cnt,
				total_expected_frequency_queries_cnt: this.frequencyUpdatesSavedTotals.total_expected_frequency_queries_cnt,
				total_expected_ws1_queries_cnt: this.frequencyUpdatesSavedTotals.total_expected_ws1_queries_cnt,
				total_expected_ws2_queries_cnt: this.frequencyUpdatesSavedTotals.total_expected_ws2_queries_cnt,
				total_failed_cnt: this.frequencyUpdatesSavedTotals.total_failed_cnt,
				total_finished_frequency_queries_cnt: this.frequencyUpdatesSavedTotals.total_finished_frequency_queries_cnt,
				total_finished_ws1_queries_cnt: this.frequencyUpdatesSavedTotals.total_finished_ws1_queries_cnt,
				total_finished_ws2_queries_cnt: this.frequencyUpdatesSavedTotals.total_finished_ws2_queries_cnt,
				total_frequency_queries_prc: this.frequencyUpdatesSavedTotals.total_frequency_queries_prc,
				total_frequency_volume_cnt: this.frequencyUpdatesSavedTotals.total_frequency_volume_cnt,
				total_frequency_ws1_queries_prc: this.frequencyUpdatesSavedTotals.total_frequency_ws1_queries_prc,
				total_frequency_ws1_volume_cnt: this.frequencyUpdatesSavedTotals.total_frequency_ws1_volume_cnt,
				total_frequency_ws2_queries_prc: this.frequencyUpdatesSavedTotals.total_frequency_ws2_queries_prc,
				total_frequency_ws2_volume_cnt: this.frequencyUpdatesSavedTotals.total_frequency_ws2_volume_cnt,
				total_rows_cnt: this.frequencyUpdatesSavedTotals.total_rows_cnt,
				total_ws1_failed: this.frequencyUpdatesSavedTotals.total_ws1_failed,
				total_ws2_failed: this.frequencyUpdatesSavedTotals.total_ws2_failed,
				total: true
			}
			return [firstRow, ...this.frequencyUpdatesSaved]
		}
	},

	async created () {
		try {
			await Promise.all([
				this.getClients(),
				this.initDataShardsAndHosts()
			])
		} catch (_) {
			this.$notify({
				type: NOTIFY_STATUS.ERROR,
				title: errors.GET_DATA
			})
		} finally {
			this.loading.clients = false
		}
		await this.initDataTable()
	},

	watch: {
		async '$route.query' (value, oldValue) {
			const filtersShards = [
				'date_from',
				'date_to',
				'client_id',
				'project_id',
				'source'
			]
			try {
				if (filtersShards.some(filter => !isEqual(value[filter], oldValue[filter]))) {
					await this.initDataShardsAndHosts()
				}
				this.initDataTable()
			} catch (_) {
				this.$notify({
					type: NOTIFY_STATUS.ERROR,
					title: errors.GET_DATA
				})
			}
		}
	},

	methods: {
		...mapActions({
			getClients: 'getClients',
			getUpdatesShards: 'updates/getUpdatesShards',
			getFrequencyUpdatesSaved: 'frequency/getFrequencyUpdatesSaved'
		}),

		async initDataShardsAndHosts () {
			const {
				dateFrom,
				dateTo,
				clientId,
				projectId,
				source
			} = this.urlFilters

			this.loading.shards = true
			await this.getUpdatesShards({
				query: {
					date_from: dateFrom,
					date_to: dateTo,
					client_ids: clientId.length || projectId.length ? clientId : ['00'],
					project_ids: projectId,
					source: source
				}
			})
			this.loading.shards = false
		},

		async initDataTable () {
			const {
				dateFrom,
				dateTo,
				clientId,
				projectId,
				source,
				shardName,
				hostName,
				onlyFailed,
				device,
				actionType,
				region,
				hideImport,
				sort
			} = this.urlFilters

			this.loading.table = true
			await this.getFrequencyUpdatesSaved({
				query: {
					date_from: dateFrom,
					date_to: dateTo,
					client_ids: clientId,
					project_ids: projectId,
					source: source,
					shard_names: shardName,
					host_names: hostName,
					only_failed: onlyFailed,
					device: device,
					action_type: actionType,
					region: region,
					hide_import: hideImport,
					sort
				}
			})
			this.loading.table = false
		},

		updateSortBy (name) {
			const sortName = this.updatedNames(name)
			this.updateUrl({ sort: sortName })
		},
		updateSortDesc (value) {
			if (value !== undefined) {
				const sortName = this.urlFilters.sort[0].replace('-', '')
				this.updateUrl({ sort: `${value ? '-' : ''}${sortName}` })
			}
		},

		updatedNames (name) {
			const nameMapping = {
				'ws.ws_types.ws1': 'ws_types.ws1',
				'ws.ws_types.ws2': 'ws_types.ws2',
				'ws.expected.ws1_queries_cnt': 'expected_ws1_queries_cnt',
				'ws.expected.ws2_queries_cnt': 'expected_ws2_queries_cnt',
				'ws.expected.total_queries_cnt': 'total_expected_frequency_queries_cnt',
				'ws.expected.error_queries_cnt': 'total_expected_error_queries_cnt',
				'ws.finished.ws1_queries_cnt': 'finished_ws1_queries_cnt',
				'ws.finished.ws2_queries_cnt': 'finished_ws2_queries_cnt',
				'ws.finished.total_queries_cnt': 'total_finished_frequency_queries_cnt',
				'ws.cnt.ws1': 'frequency_ws1_volume_cnt',
				'ws.cnt.ws2': 'frequency_ws2_volume_cnt',
				'ws.cnt.total': 'total_frequency_volume_cnt',
				'ws.prc.ws1_queries_prc': 'frequency_ws1_queries_prc',
				'ws.prc.ws2_queries_prc': 'frequency_ws2_queries_prc',
				'ws.prc.total_queries_prc': 'total_frequency_queries_prc'
			}

			return nameMapping[name] || name
		},

		findClientName (id) {
			if (this.clients && Array.isArray(this.clients)) {
				const client = this.clients.find((el) => +el.id === +id)
				return client && client.name ? client.name : ''
			}
			return ''
		},

		generateLink (item) {
			if (item) {
				return `https://ns.seowork.ru/project/${item.project_id}/edit?design=0`
			}
			return null
		}
	}
}
</script>
