<template>
	<div>
		<div class="d-flex align-center">
			<v-autocomplete
				class="mr-4 filter-item"
				:value="filters.clientId"
				:items="clients"
				v-bind="commonSelectConfig"
				item-text="name"
				item-value="id"
				label="Клиент"
				multiple
				:filter="filterByText"
				:loading="loading.clients"
				@input="updateFilters('client_id', $event)"
			>
				<template v-slot:item="{ item }">
					<span><sup>{{ item.id }}</sup> {{ item.name }}</span>
				</template>
			</v-autocomplete>
			<v-select-project
				class="mr-4 filter-item"
				:value="filters.projectId"
				multiple
				label="Проект"
				disable-client-id-from-url
				@input="updateFilters('project_id', $event)"
			/>
			<v-autocomplete
				class="mr-4 filter-item"
				:value="filters.action_type"
				:items="actionTypes"
				label="Тип сбора"
				v-bind="commonSelectConfig"
				@input="updateFilters('action_type', $event)"
			/>
			<v-menu
				v-model="menuDatePicker"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<div class="d-flex align-center">
						<v-text-field
							class="filter-item"
							v-bind="{
								...attrs,
								value: datePickerInputValue,
								label: 'Выберите даты',
								prependIcon: 'mdi-calendar',
								readonly: true,
								outlined: true,
								hideDetails: true,
								dense: true
							}"
							v-on="on"
						/>
					</div>
				</template>
				<v-date-picker
					:value="filterDates"
					range
					locale="ru"
					first-day-of-week="1"
					scrollable
					no-title
					:max="new Date().toISOString().substr(0, 10)"
					@input="updateFilters('date', $event)"
				/>
			</v-menu>
			<v-checkbox
				class="ml-15"
				:value="filters.hideImport"
				label="скрыть импортированные объекты"
				@change="updateFilters('hide_import', $event)"
			/>
		</div>
		<div class="d-flex align-center">
			<v-autocomplete
				class="mr-4 filter-item"
				:value="filters.shardName"
				:items="shards"
				v-bind="commonSelectConfig"
				label="Шард"
				multiple
				:filter="filterByText"
				:loading="loading.shards"
				@input="updateFilters('shard_name', $event)"
			>
				<template v-slot:item="{ item }">
					<span>{{ item }}</span>
				</template>
			</v-autocomplete>
			<v-autocomplete
				class="mr-4 filter-item"
				:value="filters.hostName"
				:items="hosts"
				v-bind="commonSelectConfig"
				label="Хост CH"
				multiple
				:filter="filterByText"
				:loading="loading.shards"
				@input="updateFilters('host_name', $event)"
			>
				<template v-slot:item="{ item }">
					<span>{{ item }}</span>
				</template>
			</v-autocomplete>
			<v-autocomplete
				class="mr-4 filter-item"
				:value="filters.device"
				:items="devices"
				label="Устройство"
				v-bind="commonSelectConfig"
				@input="updateFilters('device', $event)"
			/>
			<v-text-field
				class="mr-4 filter-item"
				:value="filters.region"
				label="Регион"
				v-bind="commonSelectConfig"
				@input="updateFilters('region', $event)"
			/>
			<v-checkbox
				v-model="filters.onlyFailed"
				label="Только недособранные апдейты"
				@change="updateFilters('only_failed', $event)"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { OPTIONS_DEVICE } from '@/const'
import selectProject from '@/components/selectProject'

export default {
	name: 'filters',
	components: {
		'v-select-project': selectProject
	},
	props: {
		filters: {
			type: Object,
			required: true
		},
		loading: {
			type: Object,
			required: true
		},
		actionTypes: {
			type: Array,
			required: true
		}
	},
	data () {
		return {
			menuDatePicker: false,
			filterDates: [],
			OPTIONS_DEVICE,
			commonSelectConfig: {
				outlined: true,
				dense: true,
				hideDetails: true,
				clearable: true
			}
		}
	},
	computed: {
		...mapState({
			clients: state => state.clients,
			shards: state => state.updates.shards,
			hosts: state => state.updates.hosts
		}),
		datePickerInputValue () {
			return this.filterDates.map((el) => this.formatDate(el)).join(' - ')
		},
		devices () {
			return this.OPTIONS_DEVICE.map(item => item.id)
		}
	},
	created () {
		this.setFilterDates([this.filters.dateFrom, this.filters.dateTo])
	},
	methods: {
		sortDates (dates) {
			return dates.toSorted((a, b) => new Date(a) - new Date(b))
		},
		formatDate (date) {
			return date.split('-').reverse().join('.')
		},
		updateFilters (name, value) {
			switch (name) {
			case 'date':
				this.setFilterDates(value)
				if (value && value.length && value.length > 1) {
					const {
						dateFrom,
						dateTo
					} = this.calcDates(value)
					this.$emit('update-filters', {
						date_from: dateFrom,
						date_to: dateTo
					})
				}
				break
			default:
				this.$emit('update-filters', { [name]: value || undefined })
			}
		},
		setFilterDates (value) {
			this.filterDates = this.sortDates(value)
		},
		calcDates (filtersDates) {
			const today = new Date().toISOString().split('T')[0]
			let dateFrom = today
			let dateTo = today

			const dates = this.sortDates(filtersDates)

			if (Array.isArray(filtersDates) && dates.length === 2 && dates[0] && dates[1]) {
				dateFrom = dates[0]
				dateTo = dates[1]
			}
			return {
				dateFrom,
				dateTo
			}
		},
		filterByText (item, queryText, itemText) {
			return itemText
				.toLocaleLowerCase()
				.indexOf(queryText.trim().toLocaleLowerCase()) > -1 ||
				item.id
					.toString()
					.indexOf(queryText.trim().toString()) > -1
		}
	}
}
</script>

<style scoped lang="scss">
.filter-item {
	max-width: 300px;
}
</style>
